<!-- 查看视频页面 -->
<template>
  <div class="obackGroundBox">
    <div class="closeBtn" @click="$router.go(-1)">
      <svg
        t="1687670500783"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2587"
        width="30"
        height="30"
      >
        <path
          d="M927.435322 1006.57265l-415.903813-415.903814L95.627695 1006.57265a56.013982 56.013982 0 1 1-79.20377-79.231777l415.903814-415.875807L16.423925 95.58926A56.013982 56.013982 0 0 1 95.627695 16.357483l415.903814 415.903813L927.435322 16.357483a55.985975 55.985975 0 1 1 79.175763 79.231777L590.763286 511.465066l415.847799 415.875807a55.985975 55.985975 0 1 1-79.175763 79.231777z"
          fill="#ffffff"
          p-id="2588"
        ></path>
      </svg>
    </div>
    <!-- 加conBox这个盒子是因为如果不限制宽度的话视频会很模糊,需要限制宽高 -->
    <div class="conBox">
      <video class="videoBox" ref="videoBox" controls loop>
        <source :src="url" type="video/mp4" />
        <source :src="url" type="video/ogg" />
        您的浏览器不支持 video 标签。
      </video>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const url = ref(proxy.$fun.fromCode(proxy.$route.query.url));
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
const playVideo = () => {
  setTimeout(() => {
    let box = proxy.$refs.videoBox;
    console.log(proxy.$refs.videoBox);
    box.play();
    // box.muted = false;
  }, 500);
  return "";
};
playVideo();
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.obackGroundBox {
  background-color: black;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .closeBtn {
    // border: solid white;
    position: fixed;
    right: 20px;
    top: 50px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
  .conBox {
    width: 750px;
    // height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    .videoBox {
      max-width: 100%;
      height: auto;
      max-height: 900px;
    }
  }
}
</style>
